#language-navbar {
  border-bottom: none;
}

.nav-item > .nav-link.active, .nav-item > .nav-link:hover {
  background-color: #f8f8f8;
  border-bottom: none;
  transition: none;
}

/* Don't briefly draw a blue bar under Node.js dropdown after hovering over it */
.nav-link {
  transition: none;
  /* move tabs closer together so that all the languages fit */
  padding: .5rem .6rem
}

.nav-link.nav-link-outbound > svg {
  height: 14px;
  width: 14px;
  margin-bottom: 3px;
  stroke: #0d6efd;
  fill: #0d6efd;
}

.nav-link.nav-link-outbound:focus > svg, .nav-link.nav-link-outbound:hover > svg {
  stroke: #0a58ca;
  fill: #0a58ca;
}

.nav.error > .nav-item > .nav-link.active, .nav.error > .nav-item > .nav-link:hover {
  background-color: #ffcdcd;
  border-color: #ffcdcd;
}

code {
  color: black;
}

#generated-code, #curl-code, #warnings {
  font-family: monospace;
  line-height: 1.25;
}

#curl-code {
}

#generated-code, #warnings {
  /* same as the text area */
  padding: .375rem .75rem;
  border-radius: .3rem;
  border: 1px solid;
  font-size: 1rem;
  font-weight: 400;
  border-color: #f8f8f8;
  background-color: #f8f8f8;
  min-height: calc(1.25em + .375rem + .375rem + 2px);
}

#warnings {
  border-color: #ffffdd;
  background-color: #ffffdd;
  /* if the error is very long, without this the yellow stops when you scroll right */
  overflow-x: auto;
  min-width: 100%;
}

/* At least Chrome on macOS adds a bottom margin */
pre {
  margin-bottom: 0;
}

#generated-code {
  display: block;
  overflow-x: auto;
}

/* The output should be marked as plaintext only when there's an error. */
#generated-code.language-plaintext {
  border-color: #ffcdcd;
  background-color: #ffcdcd;
}

.github-fork-ribbon:before {
  background-color: #333;
}
